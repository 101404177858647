import React from 'react';

export default function ClientLogin() {
  return (
    <div className='container' style={{ maxWidth: "95vmin", border: "solid", borderStyle: "groove", borderWidth:"3px", borderColor: "silver",}}>
      <div className='row align-items-center'>
        <div className=' container col-sm-8'>
          <h2>Client Login</h2>
        </div>
      </div>
    </div>
  );
}

